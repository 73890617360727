import React, { Component } from 'react';
import '../index.css'

class Trackpad extends Component {
    
    constructor(props) {
        super(props);
        
        this.prevTouch = {}
      }

    onTouch = (e) => {
        
        console.log(e.type)
        // touchstart, touchmove
        if(e.touches && e.touches[0]){
            
            const rect = e.currentTarget.getBoundingClientRect();
            const size = this.props.horizontal ? rect.height : rect.width
            const touchesNum = e.touches.length
            
            let x;
            let y;


            if(touchesNum > 1){
                let a;
                x = y = 0;
                /*
                for(a = 0; a < touchesNum; a++){
                    x += ((e.touches[a].clientX - rect.left) / size) / touchesNum;
                    y += ((e.touches[a].clientY - rect.top) / size) / touchesNum;
                }*/

                x = (e.touches[0].clientX - rect.left) / size; 
                y = (e.touches[0].clientY - rect.top) / size; 

                this.props.send({type:'multitouch', x, y})
                this.recordTouch('multitouch', x, y)
            }
            else{
                x = (e.touches[0].clientX - rect.left) / size; 
                y = (e.touches[0].clientY - rect.top) / size; 
    
                this.props.send({type:e.type, x, y})
                this.recordTouch(e.type,x, y)
            }
        }

        else if(e.type === 'touchend'){
            const time = new Date().getTime()
            if(this.prevTouch.type === 'touchstart'){
                if(time - this.prevTouch.time < 150){
                    this.props.send({type:'tap', x:this.prevTouch.x, y:this.prevTouch.y})
                }
            }
            else {
                this.props.send({type:e.type, x:this.prevTouch.x, y:this.prevTouch.y})
            }
            /*
            else if(time - this.prevTouch.time < 30 && this.prevTouch.type != 'multitouch' && this.prevTouch.type != 'touchend'){
                this.props.send({type:'tap', x:this.prevTouch.x, y:this.prevTouch.y})
            }
            */
        }
    }

    recordTouch = (type, x, y) => {
            //if(this.prevTouch.type == 'touchmove' && type == 'touchmove')
              //  return;

            this.prevTouch = {
                type,
                x,
                y,
                time:new Date().getTime()
            }
        
        
    }

    render() {
       console.log(this.props) 
       //const containerStyle = this.props.horizontal ? {height:'100%', width:`${(1/this.props.data.ratio * 100)}vh`} : {width:'100%', height:`${(this.props.data.ratio * 100)}vw`}
       const containerStyle = {height:'100%', width:'100%'} 
       const fontSize = this.props.horizontal ? '7vh' : '4vw';
       return (
        <div onTouchStart = {this.onTouch} onTouchMove = {this.onTouch} onTouchEnd = {this.onTouch} style = {{...containerStyle, backgroundColor:'#222', position:'relative',  overflow:'hidden' }} >
            <div style = {{ position:'absolute',width:'100%', height:'100%', top:0, bottom:0,  background: 'radial-gradient(#666 1px, transparent 0px, #333)', backgroundSize : '10px 10px'}}>
            </div>
            <div className = 'nonselectable' style = {{position:'absolute', top:0, bottom:0, width:'100%', height:'100%', fontSize: fontSize, color:'#777', display:'flex', flexDirection:'column', justifyContent:'center', textShadow: '0px 0px 4px #000'}}>
                <p>Use the screen</p>
                <p>of your phone</p>
                <p>as a touchpad</p>
            </div>
    
        </div>
        );
    }
}

export default Trackpad;
