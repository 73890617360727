import React, { Component } from 'react';

class Input extends Component {
    
/*
    componentDidMount() {
        const input = document.getElementById('textinput')
        input.focus()
        input.click()
    }
    */

    onContinue = () => {
        this.props.send({type:'submit'})
    }

    onBack = () => {
        this.props.send({type:'back'})
    }

    onChange = (e) => {
        this.props.send({type:'text', value:e.currentTarget.value})
    }
 


    render() {
        console.log(this.props)
        const buttonStyle = {marginLeft:5, marginRight:5, backgroundColor:'#eee', fontSize:18, borderRadius:8, fontWeight:'bold', minWidth: '80px', padding:8, fontFamily:'inherit' }
        
        return (
            <div style = {{width:'100%', height:'100%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <input maxLength = {this.props.data.maxlength} defaultValue = {this.props.data.value ? this.props.data.value : ''} onChange = {this.onChange} placeholder = 'Type here...' style = {{padding:6, height:32, fontFamily:'inherit', fontSize:20, color:'#fff', backgroundColor:'#333', border:'1px solid #999'}} type="text" id="textinput" name="textinput"/>
                <div id = 'footer' style ={{paddingTop:32}}>
                    <div style ={{float:'left'}}>
                        <button style = {buttonStyle} onClick = {this.onBack}>⬅️ Back</button>
                    </div>
                    <div style ={{float:'right'}}>  
                        <button style = {buttonStyle} onClick = {this.onContinue}>Continue</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Input;