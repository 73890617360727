import React from 'react';
import loader from './assets/three-dots.svg'
import './index.css'
import Screen from './components/Screen'
import Trackpad from './components/Trackpad'
import Signpad from './components/Signpad'
import Input from './components/Input'


const Wampy = require('wampy').Wampy;
const queryString = require('query-string');


export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.ws = null;
    this.pingInterval = null
    this.wakeLock = null
    this.state = {
      token: null,
      welcome: null,
      fontFamily: 'sans-serif',
      isConnected: false,
      horizontal: null,
      component: null,
      components: {
        type: 'input',
        data: {
          value: "Andrz",
          maxlength: 32
        }
      }
    }
  }


  componentDidMount() {

    function preventDefault(e) {
      e.preventDefault();
    }

    document.body.addEventListener('touchmove', preventDefault, { passive: false });
    document.addEventListener('gesturestart', preventDefault, { passive: false });

    const query = queryString.parse(window.location.search);
    const token = query.t || query.token;
    const welcome = query.w || query.welcome;
    const fontFamily = query.f || query.font;

    if (fontFamily) {
      var link = document.createElement("link")
      link.setAttribute("rel", "stylesheet")
      link.setAttribute("type", "text/css")
      link.setAttribute("href", "https://fonts.googleapis.com/css?family=" + fontFamily)
      document.getElementsByTagName("head")[0].appendChild(link)
      document.body.style.fontFamily = fontFamily;
    }

    if (welcome) {
      this.setState({ welcome })
    }

    if (token) {
      this.setState({ token });
      this.connectToServer(token);
    }

    window.addEventListener('resize', this.onResize);
    this.onResize(null)
    this.requestWakeLock()
  }

  requestWakeLock = async () => {
    if (this.wakeLock && !this.wakeLock.released) {
      return;
    }
    try {
      this.wakeLock = await navigator.wakeLock.request();
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  onResize = (event) => {

    let ratio = 1;
    if (this.state.component && this.state.component.type === 'trackpad')
      ratio = this.state.component.data.ratio;

    const screenRatio = window.innerHeight / window.innerWidth;
    const newHorizontal = screenRatio < ratio;

    if (this.state.horizontal !== newHorizontal) {
      this.setState({ horizontal: newHorizontal });
    }
  }
  connectToServer = (token) => {
    console.log("Connecting to WAMP server with token", token)
    this.ws = new Wampy('wss://sharedcontrols-dev.advent.zone/ws/', {
      realm: 'RC',
      helloCustomDetails: { token },
      onConnect: () => {
        const authid = this.ws._cache && this.ws._cache.server_wamp_features && this.ws._cache.server_wamp_features.authid ? this.ws._cache.server_wamp_features.authid : 'unknown';
        console.log('Connected to Router! authid:', authid);
        this.setState({ isConnected: true });
        this.ws.publish(token + "_remote", { type: "remoteConnected", authid })
        this.pingInterval = setInterval(this.ping, 30000);

      },
      onError: (e) => {
        console.log('Error connecting to the router!');
        console.log(e);
        this.setState({ isConnected: false });
      }
    });

    this.ws.subscribe(`${token}_display`, this.onMessage, {});
  };

  ping = () => {
    this.ws.publish('ping', {})
  }

  onMessage = (msg, data) => {
    console.log('onMessage', msg, data)
    if (msg && msg.argsDict)
      this.setState({ component: msg.argsDict.payload })

    else if (data && data.payload)
      this.setState({ component: data.payload })
  };


  sendMessage = (payload) => {
    //console.log(payload)
    this.ws.publish(this.state.token + "_remote", { type: 'action', payload })
  }


  ///////////////
  // rendering //
  ///////////////

  render() {
    if (!this.state.token) {
      return (
        <div className='container'>
          <span>
            No valid token.
          </span>
        </div>
      )
    }
    else if (!this.state.isConnected) {
      return (
        <div className='container'>
          <img src={loader} style={{ padding: 20 }} />
          <span>
            Connecting to server...
          </span>
        </div>
      )
    }
    else if (this.state.component) {
      return (
        <div className='container'>
          {this.renderComponent(this.state.component)}
        </div>
      )
    }
    else {
      return (
        <div className='container'>
          <img src={loader} style={{ padding: 20 }} />
          <span>
            {`Waiting for ${this.state.welcome || ''} data...`}
          </span>
        </div>
      )
    }
  }

  renderComponent = (component) => {
    switch (component.type) {
      case 'input':
        return <Input data={component.data} send={this.sendMessage} />;
      case 'screen':
        return <Screen body={component.body} send={this.sendMessage} requestWake={this.requestWakeLock} />;
      case 'trackpad':
        return <Trackpad data={component.data} horizontal={this.state.horizontal} send={this.sendMessage} />;
      case 'signpad':
        return <Signpad data={component.data} horizontal={this.state.horizontal} send={this.sendMessage} />;
    }
  }



}


