import React, { Component } from 'react';

class Screen extends Component {
    render() {
        console.log('screen', this.props)
        const body = this.props.body || [];
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>{
                body.map((row, ri) => {
                    return (
                        <div key={`row_${ri}`} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 18 }}>{
                            row.map((item, ii) => {
                                return this.renderItem(item, `col${ii}`)
                            })
                        }</div>
                    )
                })
            }</div>
        );
    }

    renderItem = (item, key) => {
        const { type } = item;
        const style = { marginLeft: 5, marginRight: 5 }

        switch (type) {
            case 'text':
                return (
                    <span key={key} style={{ ...style, fontSize: item.fontSize ? item.fontSize : 16 }}>
                        {item.data}
                    </span>
                )
            case 'button':
                return (
                    <button key={key} style={{ ...style, backgroundColor: '#eee', fontSize: 18, borderRadius: 8, fontWeight: 'bold', minWidth: '80px', padding: 8, fontFamily: 'inherit', }} onClick={() => {
                        this.props.send(item.data);
                        this.props.requestWake();
                    }}>
                        {item.data.label}
                    </button>
                )
            case 'image':
                return (
                    <img alt=' ' src={item.data} key={key} style={{ ...style, maxWidth: '100%', width: 'auto', height: 'auto', objectFit: 'contain' }} />
                )
        }
    }
}

export default Screen;