import React, { Component } from 'react';
import '../index.css'

class Signpad extends Component {
    
    constructor(props) {
        super(props);
        
        this.ctx = null;
        this.c = null;

        this.prevTouch = {}
      }

    componentDidMount() {

        //console.log('componentDidMount')

        const cf = document.getElementById("signpad_parent");
        const rect = cf.getBoundingClientRect();
        
        this.c = document.getElementById("signpad");
        this.c.width = rect.width;
        this.c.height = rect.height;

        this.ctx = this.c.getContext("2d")
        this.ctx.strokeStyle = '#fff'
        this.ctx.lineWidth = 3;
    }

    componentDidUpdate(prevProps){
        //console.log('componentDidUpdate')
        if(prevProps.horizontal !=  this.props.horizontal){
            const cf = document.getElementById("signpad_parent");
            const rect = cf.getBoundingClientRect();
            
            this.c = document.getElementById("signpad");
            this.c.width = rect.width;
            this.c.height = rect.height;

            this.ctx = this.c.getContext("2d")
            this.ctx.strokeStyle = '#fff'
            this.ctx.lineWidth = 3;

            this.props.send({type:'clear'})
        }
    }

    onTouch = (e) => {

        if(e.touches && e.touches[0]){
            
            const rect = e.currentTarget.getBoundingClientRect();
            //const size = this.props.horizontal ? rect.height : rect.width

            const x = (e.touches[0].clientX - rect.left) / rect.width; 
            const y = (e.touches[0].clientY - rect.top) / rect.height; 

            const xp = (e.touches[0].clientX - rect.left);
            const yp = (e.touches[0].clientY - rect.top);

            console.log(x,y)
    
            this.props.send({type:e.type, x, y})
            this.recordTouch(e.type, x, y, xp, yp)
  
            if(this.ctx){
                    
                if(e.type === 'touchstart'){
                    console.log(xp, yp)
                    this.ctx.beginPath()
                    this.ctx.moveTo(xp, yp);
                }else{
                    this.ctx.lineTo(xp, yp);
                    this.ctx.stroke(); 
                }
            }
            
        }

        else if(e.type === 'touchend'){
            this.props.send({type:e.type, x:this.prevTouch.xp, y:this.prevTouch.yp})
           
            if(this.prevTouch.type === 'touchstart'){
                this.ctx.arc(this.prevTouch.xp, this.prevTouch.yp, 1, 0, Math.PI*2)
                this.ctx.stroke(); 
            }
        }
    }

    onBack = () => {
        this.props.send({type:'back'})
    }

    onClear = () => {
        this.ctx.clearRect(0, 0, this.c.width, this.c.height);
        this.props.send({type:'clear'})
    }

    onSubmit = () => {
        this.props.send({type:'submit'})
    }

    recordTouch = (type, x, y, xp, yp) => {
        if(this.prevTouch.type == 'touchmove' && type == 'touchmove')
            return;

        this.prevTouch = {
            type, x, y, xp, yp
        }
    
    
}

    render() {
        console.log('render')
        const buttonStyle = {marginLeft:5, marginRight:5, backgroundColor:'#eee', fontSize:18, borderRadius:8, fontWeight:'bold', minWidth: '80px', padding:8, fontFamily:'inherit' }
        const containerStyle = {width:'100%', height:`${(this.props.data.ratio * 100)}vw`}
        //const containerStyle = !this.props.horizontal ? {height:'100%', width:`${(1/this.props.data.ratio * 100)}vh`} : {width:'100%', height:`${(this.props.data.ratio * 100)}vw`}
        const fontSize = this.props.horizontal ? '7vh' : '4vw';
        return (
            <div style = {{width:'100%', height:'100%', display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
                {!this.props.horizontal && this.props.data.ratio < .5 && <p>
                    Rotate for best experience
                </p>}
            <div id = 'signpad_parent' onTouchStart = {this.onTouch} onTouchMove = {this.onTouch} onTouchEnd = {this.onTouch} style = {{...containerStyle, backgroundColor:'#222', position:'relative',  overflow:'hidden' }} >
                
                <div style = {{ position:'absolute',width:'100%', height:'100%', top:0, bottom:0,  background: 'radial-gradient(#666 1px, transparent 0px, #333)', backgroundSize : '10px 10px'}}>
                </div>
                
                <div className = 'nonselectable' style = {{ position:'absolute', top:0, bottom:0, width:'100%', height:'100%', fontSize: fontSize, color:'#555', display:'flex', flexDirection:'column', justifyContent:'center', textShadow: '0px 0px 4px #000'}}>
                    <p>Sign here</p>
                </div>
                <canvas id = 'signpad' width = '100' height = '100' style = {{position:'absolute', display:'block'}}>
                    Sorry, your browser doesn't support canvas
                </canvas>
            </div>
            <div id = 'footer' style ={{paddingTop:10}}>
                <div style ={{float:'left'}}>
                    <button style = {buttonStyle} onClick = {this.onBack}>⬅️ Back</button>
                </div>
                <div style ={{float:'right'}}>  
                    <button style = {buttonStyle} onClick = {this.onClear}>Clear</button>
                    <button style = {buttonStyle} onClick = {this.onSubmit}>Submit</button>
                </div>
            </div>
        
        </div>
        );
    }
}

export default Signpad;